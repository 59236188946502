<template>
  <va-card align="center" title="about us">
    <div class="about">
      <div class="content">
        <address>
          <b>Address</b> <br>
          {{address}} <br><br>

          <b>Contact</b><br>
          Inquiry <a :href="'tel:+'+phone">{{phone}}</a> <br><br>

          <b>Visit Us</b><br>
          <a :href="visit" target="_blank">{{visit}}</a> <br><br>
        </address>
      </div>
      <div>
        <img class="icon" src="@/assets/icons/aboutus.png" />
      </div>
    </div>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en'

Vue.use(vueResource)
export default {
  name: 'about',
  created () {
    this.$http.get(config.menu.host + '/auth')
      .then(response => {
        this.address = response.data.address
        this.phone = response.data.phone
        this.visit = response.data.visit
      })
  },
  data () {
    return {
      address: '',
      phone: '',
      visit: '',
    }
  },
}
</script>

<style scoped>
.about {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
}

.icon {
  width: 14vw;
  height: 10vh;
  float: right;
  float: top;
}

address {
  text-align: left;
  font-style: italic;
}
</style>
